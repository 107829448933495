import React from "react";
import { Tooltip } from "@mui/material";

import InfoIconDark from "../../assets/svg/info-icon-dark.svg";

export default function InfoIconTooltip({
  tooltip = "",
  className = "",
  alt = "info",
  icon = null,
}) {
  return (
    <Tooltip title={tooltip} className={className}>
      <img src={icon || InfoIconDark} alt={alt} />
    </Tooltip>
  );
}
