import { extractMessage } from "../../utils/common";
import { getConversationByConversationID } from "./selectors";
import { initialState } from "./state";
import { toastify } from "../../helper/helper";

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case "initialState":
      return {
        ...initialState,
      };
    case "setChatsList":
      const { payload } = action || {};

      // Find fresh chat and include them in the chats list as they are not
      // fetched from BE APIs
      let freshChat = [];
      if (state.chats?.length > 0) {
        freshChat = state.chats?.filter((chat) =>
          state.freshChat.includes(chat.conversation_id)
        );
      }

      return {
        ...state,
        chats: [...freshChat, ...payload.chats],
        ...(state.activeConversation === -1 && payload.chats?.length > 0
          ? { activeConversation: payload.chats[0]?.conversation_id }
          : {}),
      };
    case "setIsChatsLoaded":
      return {
        ...state,
        isChatsLoaded: action.payload.isChatsLoaded,
      };
    case "broadcastSent":
      return {
        ...state,
        isWaitingSocketMessage: true,
      };
    case "setIsChatsLoading":
      return {
        ...state,
        isChatsLoading: action.payload.isChatsLoading,
      };
    case "setCurrentConversationId":
      return {
        ...state,
        previousActiveConversation: state.activeConversation,
        activeConversation: action.payload,
      };
    case "setPreviousConversationId":
      return {
        ...state,
        previousActiveConversation: action.payload,
      };
    case "setConversationInformation":
      const { conversation_id, conversation, replace = false } = action.payload;
      try {
        const _conversation = getConversationByConversationID(
          state,
          conversation_id
        );
        if (!_conversation || _conversation?.current_page === 1 || replace) {
          return {
            ...state,
            conversations: {
              ...state.conversations,
              [conversation_id]: {
                ...conversation,
              },
            },
          };
        }
      } catch (error) {
        return {
          ...state,
        };
      }
      break;
    case "setReloadChats":
      return { ...state, reloadChats: action.payload };
    case "clearMonitorData":
      return { ...state, monitorAllChats: [], monitorUnreadChats: [] };

    case "updateChatsUnreadCount":
      let { count = 0 } = action.payload;
      return {
        ...state,
        chats: state.chats?.map((chat) =>
          chat?.conversation_id === action.payload.conversation_id
            ? { ...chat, count }
            : { ...chat }
        ),
      };
    case "updateConversationInformation":
      const {
        conversation_id: _conversation_id,
        messages,
        pagination,
      } = action.payload;
      const _conversation = getConversationByConversationID(
        state,
        _conversation_id
      );
      const updatedConversation = {
        ..._conversation,
        messages: [...messages, ..._conversation.messages],
        ...pagination,
      };
      return {
        ...state,
        conversations: {
          ...state.conversations,
          [_conversation_id]: {
            ...updatedConversation,
          },
        },
      };
    case "setCurrentSearchMessageItem":
      return {
        ...state,
        currentSearchMessageItem: action.payload,
      };
    case "setTriggerReloadUnreadCountUpdate":
      return { ...state, triggerReloadUnreadCount: action.payload };
    case "setIsProfileViewOnly":
      return {
        ...state,
        isProfileView: action.payload,
      };
    case "setIsConversationViewOnly":
      return {
        ...state,
        isConversationView: action.payload,
      };

    case "setIsChatPreviewOnly":
      return {
        ...state,
        isChatPreview: action.payload,
      };

    case "setLinkPreview":
      return {
        ...state,
        linkPreviews: {
          ...state.linkPreviews,
          [action.payload.key]: action.payload.data,
        },
      };
    case "bulkUpdate":
      return {
        ...state,
        ...action.payload,
      };

    case "createFreshChatRecord":
      return {
        ...state,
        freshChat: [...state.freshChat, action.payload.id],
      };

    case "flushFreshChats":
      return {
        ...state,
        freshChat: [],
      };

    case "setCurrentNetworkFilter":
      return {
        ...state,
        currentNetworkFilters: action.payload?.clear
          ? {}
          : {
              ...action.payload,
            },
      };
    case "removeUserFromFreshChat":
      const { id } = action.payload;
      return {
        ...state,
        freshChat: state.freshChat.filter((item) => item !== id),
        chats: state.chats.filter((item) => item.conversation_id !== id),
      };
    case "removeConversationByConversationID":
      const conversations = state.conversations;
      delete conversations[action.payload.conversation_id];
      return {
        ...state,
        conversations,
        chats: [
          ...state?.chats.filter(
            (item) => item.conversation_id !== action.payload.conversation_id
          ),
        ],
      };
    case "toggleMuteStatus":
      const { conversationId } = action.payload;
      const _chats = state.chats;
      if (!_chats)
        return {
          ...state,
        };
      const chats = _chats.map((chat) =>
        chat.conversation_id === conversationId
          ? { ...chat, mute_chat: !chat.mute_chat }
          : { ...chat }
      );

      return {
        ...state,
        chats: [...chats],
      };
    case "updateGroupDetails":
      const { conversationId: _conversationId, group_details } = action.payload;
      const _currentConversation = state.conversations[_conversationId];
      if (_conversation) {
        return {
          ...state,
          conversations: {
            ...state.conversations,
            [_conversationId]: {
              ..._currentConversation,
              group_details: {
                ...group_details,
              },
            },
          },
        };
      }
      return {
        ...state,
      };

    case "pushNewMessage":
      const { message } = action.payload;
      const currentConversation =
        state.conversations[action.payload.conversationId];
      if (!currentConversation) {
        return {
          ...state,
        };
      }

      if (currentConversation.messages?.length > 0) {
        //noticed that webhook sometimes return duplicate message
        //Todo: Verify in future
        const messageAlreadyExists = currentConversation.messages.find(
          (item) => item.id === message.id
        );
        if (messageAlreadyExists) {
          return {
            ...state,
          };
        }
      }

      // setTimeout(() => {
      //   callback && callback();
      // }, [0]);

      return {
        ...state,
        conversations: {
          ...state.conversations,
          [action.payload.conversationId]: {
            ...currentConversation,
            messages: [...currentConversation.messages, message],
          },
        },
      };

    case "updateLatestMessage": {
      const { latest_message, conversation_id } = action.payload;
      try {
        const _chats = state.chats;
        if (_chats?.length < 1)
          return {
            ...state,
          };
        const chats = _chats.map((chat) =>
          chat.conversation_id === conversation_id
            ? { ...chat, latest_message, time: new Date().toISOString() }
            : { ...chat }
        );

        if (_chats[0].conversation_id !== conversation_id) {
          const chat = chats.find(
            (chat) => chat.conversation_id === conversation_id
          );
          const items = chats.filter(
            (chat) => chat.conversation_id !== conversation_id
          );

          return {
            ...state,
            chats: [chat, ...items],
          };
        }
        return {
          ...state,
          chats: [...chats],
        };
      } catch (error) {
        console.log(`error ${error.message}`);
        return {
          ...state,
        };
      }
    }
    case "setIsMessageSending": {
      return {
        ...state,
        messageSending: action.payload,
      };
    }
    case "setIsAttachment": {
      return {
        ...state,
        attachment: action.payload,
      };
    }
    case "clearBroadcastData": {
      return {
        ...state,
        isWaitingSocketMessage: false,
        socketBroadcastMessage: null,
      };
    }
    case "setBroadcastChat": {
      const broadcastMessage = action.payload.data;

      // Clear text box reply data if present
      if (state.replyChatData?.message_id) {
        state.replyChatData = { ...initialState.replyChatData };
      }

      if (broadcastMessage?.error_code || broadcastMessage?.error_message) {
        broadcastMessage?.error_message &&
          toastify("error", broadcastMessage.error_message);
        return {
          ...state,
          messageSending: false,
          attachment: false,
          isWaitingSocketMessage: false,
          socketBroadcastMessage: broadcastMessage,
          ...(broadcastMessage?.error_message ===
          "You are no longer part of this conversation."
            ? { ...initialState, reloadChats: true }
            : {}),
        };
      }

      // Check if group is updated over socket and update state
      if (
        broadcastMessage.type === "Group Chat" &&
        broadcastMessage.group_updated
      ) {
        const chatIndex = state.chats.findIndex(
          (chat) => chat.conversation_id === broadcastMessage.conversation_id
        );
        if (chatIndex !== -1) {
          state.chats[chatIndex] = {
            ...state.chats[chatIndex],
            group_user_details: broadcastMessage.group_user_details,
            title: broadcastMessage.group_title,
            total_group_members: broadcastMessage.group_members_count,
            user_image: broadcastMessage.group_icon,
          };
        }
        // Check if conversation present in state only then update
        if (
          Object.keys(state.conversations).includes(
            broadcastMessage.conversation_id.toString()
          )
        ) {
          state.conversations[broadcastMessage.conversation_id] = {
            ...state.conversations[broadcastMessage.conversation_id],
            chat: {
              ...state.conversations[broadcastMessage.conversation_id].chat,
              conversation_window_title: broadcastMessage.group_title,
            },
            group_details: {
              ...state.conversations[broadcastMessage.conversation_id]
                .group_details,
              group_icon: broadcastMessage.group_icon,
              group_info: broadcastMessage.group_info,
              group_title: broadcastMessage.group_title,
              group_user_details: broadcastMessage.group_user_details,
              total_participants: broadcastMessage.group_members_count,
            },
          };
        }

        return {
          ...state,
          isWaitingSocketMessage: false,
          socketBroadcastMessage: broadcastMessage,
        };
      }

      // Check if group is created over socket and update state
      if (
        broadcastMessage.type === "Group Chat" &&
        broadcastMessage.group_created
      ) {
        return {
          ...state,
          isWaitingSocketMessage: false,
          socketBroadcastMessage: broadcastMessage,
          chats: [
            ...state.chats,
            {
              latest_message: "",
              conversation_id: broadcastMessage.conversation_id,
              conversation_window_id: broadcastMessage?.conversation_window_id,
              title: broadcastMessage.group_title,
              time: "",
              count: 0,
              user_image: broadcastMessage.group_icon,
              receiver_id: broadcastMessage.receiver_id,
              user_deleted: false,
              mute_chat: false,
              group_user_details: broadcastMessage.group_user_details,
              total_group_members: broadcastMessage.group_members_count,
              group_admins: broadcastMessage.group_admins,
            },
          ],
          conversations: {
            ...state.conversations,
            [broadcastMessage.conversation_id]: {
              chat: {
                conversation_id: broadcastMessage.conversation_id,
                conversation_window_title: broadcastMessage.group_title,
                conversation_window_id:
                  broadcastMessage?.conversation_window_id,
                mute_chat: false,
              },
              group_details: {
                total_participants: broadcastMessage.group_members_count,
                conversation_window_id: broadcastMessage.conversation_window_id,
                group_title: broadcastMessage.group_title,
                group_icon: broadcastMessage.group_icon,
                group_user_details: broadcastMessage.group_user_details,
                group_info: broadcastMessage.group_info,
              },
              user_info: null,
              messages: [],
              current_page: null,
              total_messsages: 0,
              total_pages: 0,
              next_page: null,
            },
          },
          activeConversation:
            state.activeConversation === -1
              ? broadcastMessage.conversation_id
              : state.activeConversation,
          isChatsLoading: false,
          isChatsLoaded: true,
        };
      }

      const message = extractMessage(broadcastMessage);
      if (broadcastMessage.sender_id === broadcastMessage.receiver_id) {
        // If message sent
        let _conversations = { ...state.conversations };
        let _freshChat = [...state.freshChat];

        const chatIndex = state?.chats?.findIndex(
          (chat) => chat?.conversation_id === state?.activeConversation
        );
        // Update chat
        let _chats = [...state.chats];
        if (_chats[chatIndex]) {
          _chats[chatIndex].latest_message = broadcastMessage.message;
          _chats[chatIndex].time = broadcastMessage.send_at;
          _chats[chatIndex].file_name = broadcastMessage.file_name;
          _chats[chatIndex].conversation_type = "simple";
        }

        // Check if fresh chat then update draft conversation id with socket
        // receiver conversation id
        if (state.freshChat.includes(state.activeConversation)) {
          _conversations[broadcastMessage.receiver_conversation_id] =
            state.conversations[state.activeConversation];
          delete _conversations[state.activeConversation];
          _conversations[
            broadcastMessage.receiver_conversation_id
          ].chat.conversation_id = broadcastMessage.receiver_conversation_id;
          _conversations[
            broadcastMessage.receiver_conversation_id
          ].chat.conversation_window_id =
            broadcastMessage?.conversation_window_id;
          _freshChat = _freshChat.filter(
            (item) => item !== state.activeConversation
          );
          _chats[chatIndex].conversation_id =
            broadcastMessage.receiver_conversation_id;
          _chats[chatIndex].conversation_window_id =
            broadcastMessage?.conversation_window_id;
          state.activeConversation = broadcastMessage.receiver_conversation_id;
        }

        return {
          ...state,
          isWaitingSocketMessage: false,
          socketBroadcastMessage: broadcastMessage,
          messageSending: false,
          chats: _chats,
          freshChat: [..._freshChat],
          conversations: {
            ..._conversations,
            [state.activeConversation]: {
              ..._conversations[state.activeConversation],
              messages: [
                ..._conversations[state.activeConversation]?.messages,
                { ...message },
              ],
            },
          },
        };
      } else {
        // If Message received
        let chatIndex = -1;
        if (broadcastMessage.type === "Group Chat") {
          chatIndex = state.chats.findIndex(
            (chat) =>
              chat.conversation_id === broadcastMessage.receiver_conversation_id
          );
        } else {
          chatIndex = state.chats.findIndex(
            (chat) => chat.receiver_id === broadcastMessage.sender_id
          );
        }
        if (chatIndex !== -1) {
          // Chat previously initiated condition
          // Update chat
          let _chats = [...state.chats];
          _chats[chatIndex].latest_message = broadcastMessage.message;
          _chats[chatIndex].time = broadcastMessage.send_at;
          _chats[chatIndex].file_name = broadcastMessage.file_name;
          _chats[chatIndex].conversation_type =
            broadcastMessage?.request_meeting ? "request_meeting" : "simple";
          _chats[chatIndex].conversation_window_id =
            broadcastMessage?.conversation_window_id;

          // Get conversation id
          const conversationId = state.chats[chatIndex]?.conversation_id;

          let _conversations = { ...state.conversations };
          // Check if conversation present in state only then update
          if (
            Object.keys(state.conversations).includes(conversationId.toString())
          ) {
            _conversations[conversationId] = {
              ...state.conversations[conversationId],
              messages: [
                ...state.conversations[conversationId]?.messages,
                { ...message },
              ],
            };
          }

          return {
            ...state,
            isWaitingSocketMessage: false,
            socketBroadcastMessage: broadcastMessage,
            chats: _chats,
            conversations: _conversations,
          };
        } else {
          // Chat not initiated condition
          return {
            ...state,
            reloadChats: true,
          };
        }
      }
    }

    case "setMonitorAllChats": {
      return {
        ...state,
        monitorAllChats: action.payload,
      };
    }

    case "setMonitorUnreadChats": {
      return {
        ...state,
        monitorUnreadChats: action.payload,
      };
    }

    case "setSocketReactionsConnectionStatus": {
      return {
        ...state,
        socketReactionsConnectionStatus: action.payload,
      };
    }

    case "updateMessageReplyData": {
      return {
        ...state,
        replyChatData: action.payload,
      };
    }

    // Processes reactions channel websocket broadcast message
    case "processWebsocketReactionsMessage": {
      // Safety Check - If no active conversation, exit out of function
      if (state.activeConversation === -1) return state;

      // Data destructure
      const {
        count,
        emoji,
        emoji_unicode,
        message_id,
        reacted_users = [],
      } = action.payload || {};

      // Get active conversation and make copy to edit
      let _conversation = { ...state.conversations[state.activeConversation] };

      // Find index of message on which reaction is applied to
      const messageIndex = _conversation?.messages?.findIndex(
        (item) => item?.id === message_id
      );

      // If no message found, exit out of function (Safety check)
      if (messageIndex === -1) return state;

      // Find reactions data of the message
      let _reactions_data =
        _conversation?.messages?.[messageIndex]?.reactions?.reactions_data ||
        [];

      // Find index of reaction belonging to the socket emoji
      const messageReactionsIndex = _reactions_data?.findIndex(
        (item) => item.emoji_unicode === emoji_unicode
      );

      if (messageReactionsIndex === -1) {
        _reactions_data.push({
          count,
          emoji,
          emoji_unicode,
          reacted_users,
        });
      } else if (messageReactionsIndex !== -1 && count === 0) {
        _reactions_data.splice(messageReactionsIndex, 1);
      } else if (messageReactionsIndex !== -1 && count > 0) {
        _reactions_data[messageReactionsIndex].count = count;
        _reactions_data[messageReactionsIndex].reacted_users = reacted_users;
      }

      // Use case handle. BE gives empty reactions object in case none is present.
      // Hence we have to manually create a placeholder, in order to insert values
      if (_conversation?.messages?.[messageIndex]?.reactions === undefined) {
        _conversation.messages[messageIndex].reactions = {};
      }

      // Update placeholder conversation object
      _conversation.messages[messageIndex].reactions.reactions_data =
        _reactions_data;

      // Update state
      return {
        ...state,
        conversations: {
          ...state.conversations,
          [state.activeConversation]: {
            ..._conversation,
          },
        },
      };
    }

    default:
      return {
        ...state,
      };
  }
};
export default chatReducer;
