import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { clone, sortBy, isEmpty, findWhere } from "underscore";
import { Tooltip } from "@mui/material";

import { commonApiFN } from "../../common/api";
import { GetAllSchoolList } from "../../common/module_wise_api_url/common";
import CustomDropdown from "../../components/CustomDropdown/Cutsom-Dropdown";
import { toastify } from "../../helper/helper";
import useClickOutside from "../../hooks/useClickOutside";
import NotificationsDrawer from "../NotificationV2/NotificationDrawer";
import { updateSelectedSchool, updateSelectedPortal } from "../../redux/action";
import { INTERSTRIDE_EMBLEM } from "../../Constants";

import Notification from "../../assets/svg/notification.svg";
import Question from "../../assets/svg/Question.svg";
import User from "../../assets/svg/User.svg";
import NetworkIcon from "../../assets/svg/NetworkIcon.svg";
import TopicsIcon from "../../assets/svg/topicsIcon.svg";
import NotificationActive from "../../assets/svg/notificationsActive.svg";
import QuestionActive from "../../assets/svg/helpCenterActive.svg";
import UserActive from "../../assets/svg/profileActive.svg";
import NetworkIconActive from "../../assets/svg/chatActive.svg";
import TopicsIconActive from "../../assets/svg/topicsActive.svg";

const Header = (props) => {
  const {
    selectedSchool = null,
    refreshSchoolCount = 0,
    isSuperAdmin = false,
    careerAdminAccess = false,
    admissionsAdminAccess = false,
    user = {},
    selectedPortal = "career",
    featurePermissions = {},
    isSchool = false,
    isGeneralAdvisor = false,
  } = useSelector((state) => state.loginReducer);
  const admin_permission = user?.admin_permission || {};
  const admission_admin_permission = user?.admission_admin_permission || {};
  const dispatch = useDispatch();

  const { unreadNotificationCount, chatNotificationCount } = useSelector(
    (store) => store.notificationStore
  );

  const [schoolList, setSchoolList] = useState([]);
  const [selectedSchoolList, setSelectedSchoolList] = useState([]);
  const [pathName, setPathName] = useState(
    selectedPortal === "admission" ? "/admissions/dashboard" : "/dashboard"
  );
  const [showNotificationsDrawer, setShowNotificationsDrawer] = useState(false);

  const refNotificationDrawer = useRef();
  useClickOutside(refNotificationDrawer, () =>
    setShowNotificationsDrawer(false)
  );

  useEffect(() => {
    setPathName(window.location.pathname);

    const unlisten = props.history.listen((location, action) => {
      setPathName(location.pathname);
    });

    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSuperAdmin) getSchoolOptions();
    else
      dispatch(
        updateSelectedSchool({
          id: user.school_id,
          name: user.school_name,
          token: user.school_token,
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPortal]);

  useEffect(() => {
    if (isSuperAdmin && refreshSchoolCount > 0) getSchoolOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshSchoolCount]);

  const getSchoolOptions = async (values) => {
    const requestData = clone(GetAllSchoolList);
    const response = await commonApiFN(requestData);

    if (response && response.data && response.data.schools) {
      const sortedSchools = sortBy(response.data.schools, ({ name = "" }) => {
        return name !== null ? name.toLowerCase() : "";
      });

      updateSchools(sortedSchools);
      setSchoolList([...sortedSchools]);
    } else {
      setSchoolList([]);
      setSelectedSchoolList([]);
      dispatch(updateSelectedSchool({}));
    }
  };

  const updateSchools = (
    allSchools = schoolList,
    currentPortal = selectedPortal
  ) => {
    const filteredSchools = allSchools
      .filter(
        ({ portal_permission }) =>
          portal_permission === currentPortal || portal_permission === "both"
      )
      .map((school) => ({
        ...school,
        displayCustomSchoolOption:
          school.portal_permission === "both" ? true : false,
      }));

    setSelectedSchoolList([...filteredSchools]);

    if (selectedSchool && !isEmpty(selectedSchool)) {
      const isExists = findWhere(filteredSchools, {
        id: selectedSchool.id,
      });

      if (!isExists)
        toastify(
          "success",
          `${selectedSchool.name} doesn't have ${currentPortal} portal permission.`
        );
      dispatch(updateSelectedSchool(isExists ? isExists : {}));
    }
  };

  const showPortalToggle =
    isSuperAdmin || (careerAdminAccess && admissionsAdminAccess) ? true : false;

  return (
    <header className="header">
      <div
        className={`HeaderLeft ${
          showPortalToggle && isSuperAdmin ? "HeaderLeft--extra-width" : ""
        }`}
      >
        {isSuperAdmin ? (
          <div className="mr-2 z-index-2000">
            <CustomDropdown
              data={selectedSchoolList}
              onClick={(value) => {
                dispatch(updateSelectedSchool(value));
              }}
              displayValue="id"
              displayLabel="name"
              isMulti={false}
              placeholder="All Schools"
              value={selectedSchool}
              noSelectionText="All Schools"
              displayCustomSchoolOption={false}
              key={
                selectedPortal === "career"
                  ? `career-dropdown-${selectedSchool?.id}`
                  : `admissions-dropdown-${selectedSchool?.id}`
              }
            />
          </div>
        ) : (
          <div className="university-logo">
            <img
              src={user.school_logo ? user.school_logo : INTERSTRIDE_EMBLEM}
              alt="school"
            />
          </div>
        )}

        {showPortalToggle && (
          <div className="switch-portal-tab">
            <a
              className={
                selectedPortal === "career"
                  ? "switch-portal-tab__button switch-portal-tab__button--left switch-portal-tab__button--active"
                  : "switch-portal-tab__button switch-portal-tab__button--left"
              }
              href
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                dispatch(updateSelectedPortal("career"));
                if (isSuperAdmin) updateSchools(schoolList, "career");
              }}
              aria-label={"Student Portal"}
            >
              Career
            </a>

            <a
              className={
                selectedPortal === "admission"
                  ? "switch-portal-tab__button switch-portal-tab__button--right switch-portal-tab__button--active"
                  : "switch-portal-tab__button switch-portal-tab__button--right"
              }
              href
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                dispatch(updateSelectedPortal("admission"));
                if (isSuperAdmin) updateSchools(schoolList, "admission");
              }}
              aria-label={"Admissions Portal"}
            >
              Admissions
            </a>
          </div>
        )}
      </div>

      <div className="HeaderRight">
        {!isGeneralAdvisor && (
          <Tooltip title="Help center">
            <Link
              to="/help-center"
              className={`Question-Icon mr-2 ${
                pathName.includes("/help-center") ? "active" : ""
              }`}
            >
              <img
                src={
                  pathName.includes("/help-center") ? QuestionActive : Question
                }
                alt="Question"
              />
            </Link>
          </Tooltip>
        )}

        {!isGeneralAdvisor &&
          (isSuperAdmin ||
            (featurePermissions["topics"] &&
              (isSchool || selectedPortal === "career"
                ? admin_permission["topics"]
                : admission_admin_permission["topics"]))) && (
            <Tooltip
              title={
                selectedPortal === "career"
                  ? "Network with international peers and alumni at your institution"
                  : "Network with prospective students at your institution"
              }
            >
              <Link
                to={
                  selectedPortal === "admission"
                    ? "/admissions/chat/topics"
                    : "/communication/topics"
                }
                className={`Topics Icon mr-2 ${
                  pathName.includes("/topics") ||
                  pathName.includes("add-topic") ||
                  pathName.includes("manage-topic") ||
                  pathName.includes("discover-topic")
                    ? "active"
                    : ""
                }`}
              >
                <img
                  src={
                    pathName.includes("/topics") ||
                    pathName.includes("add-topic") ||
                    pathName.includes("manage-topic") ||
                    pathName.includes("discover-topic")
                      ? TopicsIconActive
                      : TopicsIcon
                  }
                  alt="Community"
                />
              </Link>
            </Tooltip>
          )}
        {!isGeneralAdvisor &&
          (isSuperAdmin ||
            (featurePermissions["chat"] &&
              (isSchool ||
                (selectedPortal === "career"
                  ? admin_permission["chat"]
                  : admission_admin_permission["chat"])))) && (
            <Tooltip
              title={
                selectedPortal === "career"
                  ? "Send individual or group messages to your international student community"
                  : "Send individual or group messages to prospective students"
              }
            >
              <Link
                to={
                  selectedPortal === "admission"
                    ? "/admissions/chat/messages"
                    : "/chat/messages"
                }
                className={`Network Icon ${
                  pathName.includes("/messages") ? "active" : ""
                } ${isSuperAdmin ? "mr-2" : ""}`}
              >
                <img
                  src={
                    pathName.includes("/messages")
                      ? NetworkIconActive
                      : NetworkIcon
                  }
                  alt="NetworkIcon"
                />
                {chatNotificationCount > 0 && (
                  <div className="notification-badge">
                    <span>{chatNotificationCount}</span>
                  </div>
                )}
              </Link>
            </Tooltip>
          )}

        {!isSuperAdmin && (
          <div ref={refNotificationDrawer}>
            <Tooltip title="Notifications">
              <Link
                onClick={() => setShowNotificationsDrawer((prev) => !prev)}
                className={`Bell-Icon ${
                  pathName.includes("notification") || showNotificationsDrawer
                    ? "active"
                    : ""
                }`}
              >
                <img
                  src={
                    pathName.includes("notification") || showNotificationsDrawer
                      ? NotificationActive
                      : Notification
                  }
                  alt="Notification"
                />
                {unreadNotificationCount && unreadNotificationCount !== 0 ? (
                  <div className="notification-badge">
                    <span>{unreadNotificationCount}</span>
                  </div>
                ) : (
                  <></>
                )}
              </Link>
            </Tooltip>

            <div
              className={`notifications__sidebar ${
                showNotificationsDrawer ? "notifications__sidebar_active" : ""
              }`}
            >
              <NotificationsDrawer
                setShowNotificationsDrawer={setShowNotificationsDrawer}
              />
            </div>
          </div>
        )}
        <Tooltip title="User profile">
          <Link
            to={"/my-profile"}
            className={`User-Icon ${
              pathName.includes("my-profile") ? "active" : ""
            }`}
          >
            <img
              src={pathName.includes("my-profile") ? UserActive : User}
              alt="User"
            />
          </Link>
        </Tooltip>
      </div>
    </header>
  );
};

export default Header;
