//Manage User

//School
export const AddSchool = {
  url: "admin/schools",
  method: "POST",
};
export const GetParticularSchoolDataById = {
  url: "admin/schools/",
  method: "GET",
};

export const UpdateSchool = {
  url: "admin/schools/",
  method: "PUT",
};

export const DeleteSchool = {
  url: "admin/schools/",
  method: "DELETE",
};

export const GetSchoolList = {
  url: "admin/schools",
  method: "GET",
};

export const ValidateMasterPassword = {
  url: "admin/schools/validate_master_password",
  method: "GET",
};

//Keywords
export const AddKeyword = {
  url: "admin/alumni_requests/create_keyword",
  method: "POST",
};
export const GetParticularKeywordsDataById = {
  url: "admin/alumni_requests/keywords/",
  method: "GET",
};

export const UpdateKeywords = {
  url: "admin/alumni_requests/",
  method: "PATCH",
};

export const deleteKeywords = {
  url: "admin/alumni_requests/",
  method: "DELETE",
};

export const GetKeywordsList = {
  url: "admin/alumni_requests/keywords",
  method: "GET",
};

//Admin
export const AddAdmin = {
  url: "admin/users",
  method: "POST",
};

export const DeleteAdmin = {
  url: "admin/users/",
  method: "DELETE",
};

export const GetAdminById = {
  url: "admin/users/",
  method: "GET",
};

export const UpdateAdmin = {
  url: "admin/users/",
  method: "PUT",
};

export const GetAdmins = {
  url: "admin/users/admins",
  method: "GET",
};

export const InviteAdmin = {
  url: "admin/admin_permissions/invite",
  method: "PUT",
};

export const RevokeAdmin = {
  url: "admin/admin_permissions/revoke",
  method: "PUT",
};

//Alumni
export const AddAlumnis = {
  url: "/add",
  method: "POST",
};

export const GetInvitedAlumni = {
  url: "admin/alumni_requests/invited",
  method: "GET",
};

export const GetPendingAlumni = {
  url: "admin/alumni_requests/pending_requests",
  method: "GET",
};

export const GetActiveAlumni = {
  url: "admin/alumni_requests/active",
  method: "GET",
};

export const GetArchiveAlumni = {
  url: "admin/alumni_requests/archived",
  method: "GET",
};

export const approveRequest = {
  url: "admin/alumni_requests/approve_lead",
  method: "POST",
};

export const denyrequest = {
  url: "admin/alumni_requests/deny",
  method: "POST",
};

//leads
export const GetLeads = {
  url: "admin/user_leads",
  method: "GET",
};

export const DeleteLeads = {
  url: "admin/user_leads/",
  method: "DELETE",
};

export const UpdateLeads = {
  url: "admin/user_leads/",
  method: "PUT",
};

//User
export const AddUsers = {
  url: "/add",
  method: "POST",
};

export const AddInvitedUser = {
  url: "admin/users/invite_school_user",
  method: "POST",
};

export const importUsers = {
  url: "admin/users/import_data",
  method: "POST",
};

export const GetInvitedUsersList = {
  url: "admin/users/school_user_invites",
  method: "GET",
};

export const GetAllUsersList = {
  url: "admin/users",
  method: "GET",
};

export const ExportAllUsersList = {
  url: "admin/users/export",
  method: "GET",
};

export const ExportAllInviteUsersList = {
  url: "admin/users/export_invite",
  method: "GET",
};

export const deleteRegisteredUser = {
  url: "admin/users/",
  method: "DELETE",
};
export const deleteInvitedUserById = {
  url: "admin/users/delete_invited_user/",
  method: "DELETE",
};

export const toggleMentorStatus = {
  url: "admin/users/toggle_mentor/",
  method: "PATCH",
};

export const toggleInvitedMentor = {
  url: "admin/users/toggle_invited_mentor/",
  method: "PATCH",
};

export const EditPrimaryEmail = {
  url: "admin/users/update_primary_email",
  method: "PUT",
};

export const MakePrimaryEmail = {
  url: "admin/users/make_primary",
  method: "PUT",
};

//Advisors
export const GetAllAdvisorsList = {
  url: "admin/advisors",
  method: "GET",
};

export const AddAdvisors = {
  url: "admin/advisors",
  method: "POST",
};

export const DeleteAdvisors = {
  url: "admin/advisors/",
  method: "DELETE",
};

export const GetAdvisorsById = {
  url: "admin/advisors/",
  method: "GET",
};

export const UpdateAdvisors = {
  url: "admin/advisors/",
  method: "PUT",
};

//InterstrideTeam
export const getInterstrideTeam = {
  url: "admin/super_admins",
  method: "GET",
};

export const deleteInterstrideTeam = {
  url: "admin/super_admins/",
  method: "DELETE",
};

export const getInterstrideTeamById = {
  url: "admin/super_admins/",
  method: "GET",
};

export const addInterstrideTeam = {
  url: "admin/super_admins",
  method: "POST",
};

export const updateInterstrideTeam = {
  url: "admin/super_admins/",
  method: "PATCH",
};

// Common for users, ambassadors, prospects
export const GetUserFilters = {
  url: "admin/users/filters",
  method: "GET",
};

export const GetEngagementFilters = {
  url: "admin/mixpanel_events/filters",
  method: "GET",
};

export const GetAmbassadorsForSuperAdmin = {
  url: "admin/ambassadors/career",
  method: "GET",
};

export const GetAssignedAmbassadorsStudents = {
  url: "admin/ambassadors/career",
  method: "GET",
};

export const GetPendingAmbassadorsStudents = {
  url: "admin/ambassadors/career",
  method: "GET",
};

export const GetInvitedAmbassadorsStudents = {
  url: "admin/users/school_user_invites",
  method: "GET",
};

export const GetAllAmbassadorsStudents = {
  url: "admin/ambassadors/career",
  method: "GET",
};

export const GetAssignedAmbassadorsAdmissions = {
  url: "admin/ambassadors",
  method: "GET",
};

export const GetPendingAmbassadorsAdmissions = {
  url: "admin/ambassadors",
  method: "GET",
};

export const GetInvitedAmbassadorsAdmissions = {
  url: "admin/users/school_user_invites",
  method: "GET",
};

export const GetAllAmbassadorsAdmissions = {
  url: "admin/ambassadors",
  method: "GET",
};

export const ToogleMeetingStatus = {
  url: "admin/users/{user_id}/toggle_request_meeting",
  method: "PATCH",
};

export const ToggleShowProfile = {
  url: "admin/ambassadors/{user_id}/update_profile",
  method: "PATCH",
};

export const GetInvitedAlumniAmbassadors = {
  url: "admin/alumni_requests/invited",
  method: "GET",
};

export const GetInvitedStaffAmbassadors = {
  url: "admin/alumni_requests/staff_invited",
  method: "GET",
};

export const InviteAmbassador = {
  url: "admin/ambassadors/invites",
  method: "PUT",
};

export const RevokeAmbassador = {
  url: "admin/ambassadors/revoke",
  method: "PUT",
};

export const DeleteRegisteredAmbassador = {
  url: "admin/users/",
  method: "DELETE",
};

export const DeleteInvitedAmbassador = {
  url: "admin/ambassadors/",
  method: "DELETE",
};

export const GetUserProfileDetails = {
  url: "profile/info",
  method: "GET",
};

export const GetAmbassadorProfileDetails = {
  url: "admin/ambassadors/",
  method: "GET",
};

export const BlockUnblockUser = (id = null) => ({
  url: `admission_portal/admin/users/${id}/block_unblock`,
  method: "PUT",
});

export const AddSingleStaff = {
  url: "admin/users/invite_school_user",
  method: "POST",
};

export const AddMultipleStaff = {
  url: "admin/users/import_data",
  method: "POST",
};

export const GetInvitedStaff = {
  url: "admin/alumni_requests/staff_invited",
  method: "GET",
};

/** BULK DELETE - START */

// STUDENT - START
export const SelectiveDeleteStudents = {
  url: "admin/users/remove",
  method: "DELETE",
};

export const SelectiveDeleteInvitedStudents = {
  url: "admin/users/invites/remove",
  method: "DELETE",
};

export const DeleteAllStudents = {
  url: "admin/users/remove_all",
  method: "DELETE",
};

export const DeleteAllInvitedStudents = {
  url: "admin/users/school_user_invites",
  method: "DELETE",
};
// STUDENT - END

// ALUMNI - START
export const SelectiveDeleteAlumni = {
  url: "admin/users/remove",
  method: "DELETE",
};

export const SelectiveDeleteInvitedAlumni = {
  url: "admin/users/invites/remove",
  method: "DELETE",
};

export const DeleteAllAlumni = {
  url: "admin/users/remove_all",
  method: "DELETE",
};

export const DeleteAllInvitedAlumni = {
  url: "admin/alumni_requests/invited",
  method: "DELETE",
};
// ALUMNI - END

// STAFF - START
export const SelectiveDeleteStaff = {
  url: "admin/users/remove",
  method: "DELETE",
};

export const SelectiveDeleteInvitedStaff = {
  url: "admin/users/invites/remove",
  method: "DELETE",
};

export const DeleteAllStaff = {
  url: "admin/users/remove_all",
  method: "DELETE",
};

export const DeleteAllInvitedStaff = {
  url: "admin/alumni_requests/staff_invited",
  method: "DELETE",
};
// STAFF - END

// ADMIN - START
export const SelectiveDeleteAdmins = {
  url: "admin/users/remove",
  method: "DELETE",
};

export const DeleteAllAdmins = {
  url: "admin/users/remove_all",
  method: "DELETE",
};
// ADMIN - END

// POSPECT - START
export const SelectiveDeleteProspects = {
  url: "admin/prospects/remove",
  method: "DELETE",
};

export const SelectiveDeleteInvitedProspects = {
  url: "admin/prospects/invites/remove",
  method: "DELETE",
};

export const DeleteAllProspects = {
  url: "admin/prospects/remove_all",
  method: "DELETE",
};

export const DeleteAllInvitedProspects = {
  url: "admin/users/school_user_invites",
  method: "DELETE",
};
// POSPECT - END

// STUDENT AMBASSADOR - START
export const SelectiveDeleteStudentAmbassadors = {
  url: "admin/users/remove",
  method: "DELETE",
};

export const SelectiveDeleteInvitedStudentAmbassadors = {
  url: "admin/ambassadors/invites/remove",
  method: "DELETE",
};

export const DeleteAllStudentAmbassadors = {
  url: "admin/ambassadors/remove_all",
  method: "DELETE",
};

export const DeleteAllInvitedStudentAmbassadors = {
  url: "admin/users/school_user_invites",
  method: "DELETE",
};
// STUDENT AMBASSADOR - END

// ALUMNI AMBASSADOR - START
export const SelectiveDeleteAlumniAmbassadors = {
  url: "admin/users/remove",
  method: "DELETE",
};

export const SelectiveDeleteInvitedAlumniAmbassadors = {
  url: "admin/ambassadors/invites/remove",
  method: "DELETE",
};

export const DeleteAllAlumniAmbassadors = {
  url: "admin/ambassadors/remove_all",
  method: "DELETE",
};

export const DeleteAllInvitedAlumniAmbassadors = {
  url: "admin/alumni_requests/invited",
  method: "DELETE",
};
// ALUMNI AMBASSADOR - END

// STAFF AMBASSADOR - START
export const SelectiveDeleteStaffAmbassadors = {
  url: "admin/users/remove",
  method: "DELETE",
};

export const SelectiveDeleteInvitedStaffAmbassadors = {
  url: "admin/ambassadors/invites/remove",
  method: "DELETE",
};

export const DeleteAllStaffAmbassadors = {
  url: "admin/ambassadors/remove_all",
  method: "DELETE",
};

export const DeleteAllInvitedStaffAmbassadors = {
  url: "admin/alumni_requests/staff_invited",
  method: "DELETE",
};
// STAFF AMBASSADOR - END

/** BULK DELETE - END */

/** REINVITE - START */
export const ReInviteSelectiveUser = {
  url: "admin/users/reinvite",
  method: "POST",
};

export const ReInviteAllStudents = {
  url: "admin/users/school_user_invites",
  method: "POST",
};

export const ReInviteAllAlumni = {
  url: "admin/alumni_requests/invited",
  method: "POST",
};

export const ReInviteAllStaff = {
  url: "admin/alumni_requests/staff_invited",
  method: "POST",
};

export const ReInviteAllProspects = {
  url: "admin/users/school_user_invites",
  method: "POST",
};

export const ReInviteAllStudentAmbassadors = {
  url: "admin/users/school_user_invites",
  method: "POST",
};

export const ReInviteAllAlumniAmbassadors = {
  url: "/admin/alumni_requests/invited",
  method: "POST",
};

export const ReInviteAllStaffAmbassadors = {
  url: "admin/alumni_requests/staff_invited",
  method: "POST",
};
/** REINVITE - END */
