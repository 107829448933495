import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Bugsnag from "@bugsnag/js";
import { Formik } from "formik";
import moment from "moment";
import { isEmpty, get, clone } from "underscore";

import { commonApiFN } from "../../common/api";
import {
  AdminLogin,
  checkEmailAPI, // ForgotPasswordApi,
  ssoSignInAPI,
} from "../../common/module_wise_api_url/onboarding";
import { store } from "../../redux/store";
import {
  toastify,
  getUserApp,
  getUserWindow,
  getUserBrowser,
  setMSClarityTagsOnLogin,
  setMSClarityTagsAfterLogin,
  clearLocalStorage,
} from "../../helper/helper";
import {
  updateSSOStatus,
  setUserData,
  wipeOutSavedRoute,
  resetUserData,
} from "../../redux/action";
import Button from "../../components/Button/Button";
import Checkbox from "../../components/Checkbox/Checkbox";
import TextBox from "../../components/Textbox/Textbox";
import { INTERSTRIDE_LOGO_WHITE, userTypes } from "../../Constants";
import { getRedirectRouteFromURL } from "../../utils/common";
import PasswordTextbox from "../../components/PasswordTextbox/PasswordTextbox";

const Login = (props) => {
  const globalState = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [keepSignedIn, setKeepSignedIn] = useState(true);

  const handleLoginNavigation = () => {
    if (globalState?.user.user_type === userTypes.GENERAL_ADVISOR) {
      props.history.push("/appointment");
    } else {
      const redirectPath = getRedirectRouteFromURL();
      props.history.push(redirectPath);
      dispatch(wipeOutSavedRoute());
    }
  };

  useEffect(() => {
    const searchParmas = window.location.search;
    const params = new URLSearchParams(searchParmas);
    const token = params.get("sso");
    if (!isEmpty(token) && token !== "unauthorized") {
      ssoLogin(token);
    } else if (!isEmpty(token) && token === "unauthorized") {
      toastify(
        "error",
        "You are not authorized to access Interstride Admin portal."
      );
    } else {
      const state = store.getState();
      const keepSignedIn = localStorage.getItem("keepSignedIn");
      const isLogin = sessionStorage.getItem("isLogin");
      const currDate = moment().add(5, "minutes").unix();
      const authExpire = get(
        state,
        ["loginReducer", "user", "auth_token_expiration"],
        null
      );
      const authExpiration = moment(authExpire).unix();

      if (
        globalState?.isLogin &&
        (isLogin === "1" || keepSignedIn === "1" || currDate <= authExpiration)
      ) {
        if (!isLogin) sessionStorage.setItem("isLogin", "1");
        handleLoginNavigation();
      } else if (currDate >= authExpiration) {
        sessionStorage.clear();
        clearLocalStorage();
        dispatch(resetUserData());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (globalState?.isLogin) {
      handleLoginNavigation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [globalState?.isLogin]);

  const ssoLogin = async (token) => {
    const loginData = clone(ssoSignInAPI);
    const firebaseToken = globalState?.firebaseToken;

    loginData.data = {
      token: token,
      user_device: {
        platform: 302,
        device_name: getUserBrowser(),
        device_token: firebaseToken,
        app_version: getUserApp(),
        os_version: getUserWindow(),
      },
    };
    setMSClarityTagsOnLogin(token, true);
    const response = await commonApiFN(loginData);

    if (response.success) {
      const { users = {} } = response.data;
      dispatch(updateSSOStatus(true));

      sessionStorage.setItem("isLogin", "1");
      localStorage.setItem("keepSignedIn", keepSignedIn ? "1" : "0");

      Bugsnag.setUser(users.id, users.email, users.name);
      setMSClarityTagsAfterLogin(users, true);
      dispatch(setUserData(users));
    } else {
      if (response.message) setError(response.message);
      else
        setError("You don't have the necessary authorization for this action.");
    }
  };

  const emailCheck = async (values) => {
    const request = clone(checkEmailAPI);
    request.params = { email: values.email };

    const response = await commonApiFN(request);

    if (response.success) {
      if (response.data.sso === true) {
        window.location.href =
          response.data.data.base_url +
          "?entityID=" +
          response.data.data.entity_id +
          "&target=" +
          response.data.data.target_url;
      } else {
        setShowPassword(true);
      }
    } else {
      if (response.message) setError(response.message);
      else
        setError("You don't have the necessary authorization for this action.");
    }
    return;
  };

  const login = async (values) => {
    const loginData = clone(AdminLogin);

    const firebaseToken = globalState?.firebaseToken;

    loginData.data = {
      user: {
        email: values.email,
        password: values.password,
      },
      user_device: {
        platform: 302,
        device_name: getUserBrowser(),
        device_token: firebaseToken,
        app_version: getUserApp(),
        os_version: getUserWindow(),
      },
    };

    setMSClarityTagsOnLogin(values.email);

    const response = await commonApiFN(loginData);

    if (response.success) {
      const { users = {} } = response.data;
      if (
        users.user_type &&
        (users.user_type === "super_admin" ||
          users.user_type === "school_admin" ||
          users.user_type === "school" ||
          users.user_type === userTypes.GENERAL_ADVISOR)
      ) {
        sessionStorage.setItem("isLogin", "1");
        localStorage.setItem("keepSignedIn", keepSignedIn ? "1" : "0");

        Bugsnag.setUser(users.id, users.email, users.name);
        setMSClarityTagsAfterLogin(users);
        dispatch(setUserData(users));
      } else {
        setError("You don't have the necessary authorization for this action.");
      }
    } else {
      setError(response.message);
    }
    return;
  };

  return (
    <section className="OnboardingWrapper">
      <div className="left-content">
        <div className="left-content-container">
          <div className="brand-name">
            <a href>
              <img src={INTERSTRIDE_LOGO_WHITE} alt="brand" />
            </a>
          </div>
          <div className="text-content">
            <h1>Attract, engage, and support international students</h1>
            <h4>
              An integrated platform to support international students and
              alumni at every step of the way, from admissions through
              graduation.
            </h4>
          </div>
        </div>
      </div>
      <div className="right-content">
        <div className="right-content-container">
          <div className="login-div">
            <h1 className="text-center">Welcome to the Admin portal </h1>{" "}
            <Formik
              initialValues={{ email: "", password: "" }}
              validate={(values) => {
                setError(null);

                const errors = {};
                if (!values.email) {
                  errors.email = "Please enter Email";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Please enter valid Email";
                }

                if (showPassword) {
                  if (!values.password) {
                    errors.password = "Please enter Password";
                  } else if (values.password.trim().length === 0) {
                    errors.password = "Password is invalid";
                  }
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                if (showPassword) {
                  return login(values);
                } else {
                  setSubmitting(false);
                }
                return;
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form
                  onSubmit={handleSubmit}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (!showPassword && !errors.email && values.email) {
                        emailCheck(values);
                      } else handleSubmit();
                    }
                  }}
                >
                  <div className="form-group">
                    <TextBox
                      name="email"
                      placeholder="Enter your .edu email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      error={errors.email && touched.email}
                      errorText={errors.email}
                    />
                  </div>
                  {showPassword && (
                    <div className="form-group">
                      <PasswordTextbox
                        name="password"
                        placeholder="Enter your password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        error={errors.password && touched.password}
                        errorText={errors.password}
                      />
                    </div>
                  )}

                  <div className="form-group KeepMeSignin">
                    <Checkbox
                      value={keepSignedIn}
                      isChecked={keepSignedIn}
                      onClick={(e) => setKeepSignedIn(e.target.checked)}
                    />
                    <span className="label">
                      Keep me signed in on this device
                    </span>
                  </div>

                  {error && error?.length > 0 && (
                    <div className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error mb-5">
                      {error}
                    </div>
                  )}

                  <Button
                    type="primary"
                    noCapital={true}
                    className="login-button"
                    isSubmitting={isSubmitting}
                    customClassName="w-full mb-40"
                    onClick={(e) => {
                      if (!showPassword && !errors.email && values.email) {
                        return emailCheck(values);
                      }
                      return handleSubmit(e);
                    }}
                  >
                    Log in
                  </Button>
                  {showPassword && (
                    <div className="text-center forgot-password">
                      <a
                        href="/forgot-password"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          props.history.push(`/forgot-password`);
                        }}
                      >
                        Forgot password?
                      </a>
                    </div>
                  )}
                </form>
              )}
            </Formik>
          </div>
          <p className="text-center toc-text">
            Our{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={"https://www.interstride.com/terms-and-conditions/"}
            >
              TOC
            </a>{" "}
            and{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={"https://www.interstride.com/privacy-policy/"}
            >
              Privacy Policy
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Login;
