import React, { useState } from "react";
import { Popover, Tooltip, Typography } from "@mui/material";

import IconButton from "../IconButton/IconButton";
import { cancelRequest } from "../../common/api";

import ExportIcon from "../../assets/svg/export.svg";
import InviteEmployerSVG from "../../assets/svg/Icon-Dashboard-Invite-Employer.svg";
import ImportIcon from "../../assets/svg/Import.svg";
import InfoIconDark from "../../assets/svg/info-icon-dark.svg";
import InfoIconTooltip from "../InfoIconTooltip/InfoIconTooltip";

const ContentHeader = ({
  header = "",
  subHeader = null,
  tabs = null,
  activeTab,
  changeTab = () => {},
  exportOption = false,
  onExportClick = () => {},
  importOption = false,
  redirectImport = false,
  disableRedirectImport = false,
  onImportClick = () => {},
  isExportSelction = false,
  customOption = false,
  disableRecordActions = true,
  tooltip = null,
  customImportBtnText,
  customExportBtnText,
  setShowEmployerInviteModal = () => {},
  addInfoIcon = false,
  infoText = "",
  cancelAPIRef = false,
  isExporting = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(false);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const switchTab = (newTab) => {
    if (cancelAPIRef?.current) {
      cancelRequest(
        cancelAPIRef?.current,
        "Operation cancelled because of tab change."
      );
    }
    changeTab(newTab);
  };

  return (
    <>
      {header === "" ? (
        <div className="page-header DiscoverAdd">
          <div className="left">
            <h3>{header}</h3>
            <div className="tabs-div">
              {tabs.map((tab) => (
                <>
                  <div
                    className={activeTab === tab ? "active" : ""}
                    onClick={() => switchTab(tab)}
                  >
                    {tab}
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="page-header">
          <div className="left">
            <div className="d-flex justify-content-center align-items-center">
              <h3>{header}</h3>
              {addInfoIcon ? (
                <img
                  src={InfoIconDark}
                  className="ml-2 info-icon-large"
                  alt="Info Icon"
                  onClick={(e) => setAnchorEl(e.target)}
                />
              ) : null}
            </div>
            {addInfoIcon ? (
              <Popover
                id={id}
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                className="popover-wrapper"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <Typography>
                  <p>{infoText}</p>
                </Typography>
              </Popover>
            ) : null}
            <div className="tabs-div">
              {tabs?.map((tab) => (
                <>
                  {typeof tab === "object" && tab.logo && tab.title ? (
                    <div
                      className={`${activeTab === tab.title ? "active" : ""} ${
                        tab.customClassName || ""
                      }`}
                      onClick={() => switchTab(tab.title)}
                    >
                      <img src={tab.logo} alt={tab.title} />
                      <div>{tab.title}</div>
                      {tab?.infoIconText?.length > 0 && (
                        <InfoIconTooltip tooltip={tab.infoIconText} />
                      )}
                    </div>
                  ) : typeof tab === "object" && tab !== null ? (
                    <Tooltip title={tab.tooltip || ""}>
                      <div
                        className={activeTab === tab.title ? "active" : ""}
                        onClick={() => switchTab(tab.title)}
                      >
                        {tab.title}
                      </div>
                    </Tooltip>
                  ) : (
                    <div
                      className={activeTab === tab ? "active" : ""}
                      onClick={() => switchTab(tab)}
                    >
                      {tab}
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
          <div className="right ml-auto import-options">
            {header === "Jobs & Internships" && (
              <IconButton
                onClick={() => setShowEmployerInviteModal((prev) => !prev)}
              >
                <img src={InviteEmployerSVG} alt="icon" />
                <span className="text-label">Invite an employer</span>
              </IconButton>
            )}
            {isExportSelction && (
              <IconButton onClick={onExportClick}>
                <img src={ExportIcon} alt="export" />
                <span className="text-label">Export Selection</span>
              </IconButton>
            )}
            {importOption && (
              <IconButton tooltip={tooltip}>
                <label for="file" className="icon-button">
                  <img src={ImportIcon} alt="import" />
                  <span className="text-label">
                    {customImportBtnText && customImportBtnText != null
                      ? customImportBtnText
                      : "Import"}
                  </span>
                </label>
                <input
                  type="file"
                  id="file"
                  accept=".xlsx, .xls"
                  onChange={onImportClick.bind(this)}
                />
              </IconButton>
            )}
            {redirectImport && (
              <IconButton
                tooltip={tooltip}
                onClick={onImportClick}
                isDisabled={disableRedirectImport}
              >
                <label for="file">
                  <img src={ImportIcon} alt="import" /> Import
                </label>
              </IconButton>
            )}

            {exportOption && (
              <>
                <IconButton
                  onClick={() => {
                    onExportClick();
                  }}
                  isDisabled={disableRecordActions}
                  isSubmitting={isExporting}
                >
                  <img src={ExportIcon} alt="export" />
                  <span className="text-label">
                    {customExportBtnText && customExportBtnText != null
                      ? customExportBtnText
                      : "Export"}
                  </span>
                </IconButton>
              </>
            )}
            {customOption && <>{customOption}</>}
          </div>
          {subHeader && <h4>{subHeader}</h4>}
        </div>
      )}
    </>
  );
};

export default ContentHeader;
