import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchChatsList } from "../api/chat";
import { useId } from "./useId";

export const useChatsList = () => {
  const { id } = useId();

  const chatStore = useSelector((state) => state.chatStore);
  const { selectedPortal, isSuperAdmin } = useSelector(
    (state) => state.loginReducer
  );
  const {
    chats,
    isChatsLoaded,
    isChatsLoading,
    // activeConversation,
    reloadChats,
  } = chatStore || {};
  const dispatch = useDispatch();

  const loadChats = async () => {
    try {
      dispatch({
        type: "bulkUpdate",
        payload: {
          isChatsLoading: true,
        },
      });
      const response = await fetchChatsList(selectedPortal);
      if (response.success) {
        dispatch({
          type: "setChatsList",
          payload: {
            chats: response.data.direct_chats,
          },
        });
      }

      dispatch({
        type: "bulkUpdate",
        payload: {
          isChatsLoaded: true,
          isChatsLoading: false,
          reloadChats: false,
        },
      });
    } catch (error) {
      dispatch({
        type: "bulkUpdate",
        payload: {
          isChatsLoaded: true,
          isChatsLoading: false,
          reloadChats: false,
        },
      });
    }
  };

  useEffect(() => {
    if (!id || !reloadChats || isSuperAdmin) return;

    loadChats();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, selectedPortal, reloadChats, isSuperAdmin]);

  return {
    chats,
    isChatsLoaded,
    isChatsLoading,
  };
};
